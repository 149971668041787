<template>
  <div>
    <p class="text_32 text_dark text_b mb_20">
      Клиенты
    </p>

    <div class="common__white-block">
      <div class="flex mb_24">
        <el-input
          class="mr_24"
          v-model="searchQuery"
          placeholder="Поиск: VIN, ФИО, номер телефона, номер авто"
          @input="search" />

        <el-button class="el-button-32" type="primary" @click="$router.push('/clients/new')">
          Добавить
        </el-button>
      </div>

      <el-skeleton style="padding: 16px" :rows="10" v-if="cars.loading && !cars.items.length" animated/>

      <div v-else>
        <el-table
          :data="cars.items"
          class="el-table__no-header mb_24"
          style="width: 100%"
        >

          <el-table-column
            min-width="180">
            <template slot-scope="scope">
              <p class="text_15 text_dark text_uppercase">
                {{ scope.row.registration_number }}
              </p>

              <p class="text_15 text_dark text_uppercase">
                {{ scope.row.vin }}
              </p>
            </template>
          </el-table-column>

          <el-table-column
            min-width="180">

            <template slot-scope="scope">
              <p class="text_15 text_dark">
                {{ scope.row.vendor }} {{ scope.row.model }}
              </p>

              <p class="text_15 text_dark">
                {{ scope.row.year }}
              </p>
            </template>
          </el-table-column>

          <el-table-column
            min-width="180">
            <template slot-scope="scope">
              <p class="text_15 text_dark">
                {{ scope.row.color }}
              </p>
            </template>
          </el-table-column>

          <el-table-column
            min-width="180">
            <template slot-scope="scope">
              <p class="text_15 text_dark">
                {{name(scope.row.owner)}}
              </p>

              <p class="text_15 text_dark">
                {{ scope.row.owner.phone | VMask('+# (###) ###-####') }}
              </p>
            </template>
          </el-table-column>

          <el-table-column
            align="right"
            min-width="180">
            <template slot-scope="scope">
              <div class="text_15 text_blue cp" @click="$router.push(`/clients/${scope.row.id}`)">
                Редактировать
              </div>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          background
          layout="prev, pager, next"
          :current-page.sync="carsFilters.offset"
          :total="cars.count"
          :page-size.sync="carsFilters.limit"
          @current-change="handlePageChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { CARS_FILTERS_CHANGE, CARS_FILTERS_RESET, CARS_LIST_REQUEST } from '@/store/actions/cars';
import getUserFullName from '@/services/getUserFullName';
import { OWNERS_LIST_REQUEST } from '@/store/actions/owners';

export default {
  name: 'Clients',
  data() {
    return {
      filters: null,
      searchQuery: '',
    };
  },
  created() {
    this.$store.commit(CARS_FILTERS_RESET);
    this.$store.dispatch(CARS_LIST_REQUEST);

    this.filters = JSON.parse(JSON.stringify(this.carsFilters));
  },
  watch: {
    carsFilters: {
      handler() {
        this.filters = JSON.parse(JSON.stringify(this.carsFilters));
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['cars', 'carsFilters']),
  },
  methods: {
    name(owner) {
      return getUserFullName(owner).shortName;
    },
    search: _.debounce(function () {
      this.filters.search = this.searchQuery;

      this.$store.dispatch(CARS_FILTERS_CHANGE, this.filters);
    }, 400),
    handlePageChange(val) {
      this.filters.offset = val;

      this.$store.dispatch(CARS_FILTERS_CHANGE, this.filters);
    },
  },
};
</script>

<style scoped>

</style>
